import Swiper, { Navigation, Scrollbar } from 'swiper';

const initSwiper = () => {
  const swiperEl = document.querySelector(".swiper");

  if (swiperEl) {
    var spaceBetween = ((swiperEl.hasAttribute('data-space-between')) ? parseInt(swiperEl.dataset.spaceBetween) : 16);
    var initialSlide = ((swiperEl.hasAttribute('data-initial-slide')) ? parseInt(swiperEl.dataset.initialSlide) : 1);
    initialSlide = isNaN(initialSlide) ? 0 : initialSlide - 1;

    let swiper = new Swiper('.swiper', {
      modules: [Navigation, Scrollbar],
      slidesPerView: 'auto',
      direction: 'horizontal',
      spaceBetween: spaceBetween,
      initialSlide: initialSlide,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        hide: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const slideCountEl = document.querySelector(".swiper-slide-count");
    if(slideCountEl) {
      slideCountEl.textContent = `${swiper.realIndex + 1} / ${swiper.slides.length}`;
      swiper.on('transitionEnd', function() {
        slideCountEl.textContent = `${swiper.realIndex + 1} / ${swiper.slides.length}`;
      });
    }

  }
}

export { initSwiper };